import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Language } from 'src/app/enums/language';
import { SharedService } from 'src/app/services/shared.service';
import { LayuutService } from './layout.service';
import { MainInfoViewModel } from './main-info.model';
import { CompanyEnum } from 'src/app/enums/company.enum';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  mainInfo: MainInfoViewModel = new MainInfoViewModel()
  code: string;
  isLoaded: boolean = false;
  CompanyEnum=CompanyEnum
  
  constructor(
    private sharedService: SharedService,
    private _layoutService: LayuutService,
    private _router: Router
  ) { }


  ngOnInit(): void {
    // this.sharedService.setCustomerCode('')
    // this.sharedService.getCodeStatus().subscribe(response=>{
    //   console.log(response)
    //   if(!this.isLoaded&&response.Success){
    //     console.log("entered")
    //     this.code = response.Data
    //     this.getMainInfo();
    //   }
    // });
    this.sharedService.getCompanyMainInfoStatus().subscribe(response => {
      if (!this.isLoaded && response.Success) {
        this.sharedService.setLayoutLoaded(true)
        this.mainInfo = response.Data;
        this.isLoaded = true


      }
    })

  }

  getMainInfo() {
    this.isLoaded = false
    this.sharedService.setLayoutLoaded(false)
    this._layoutService.getMainInfo(this.code).subscribe(res => {
      if (res.Success) {
        this.mainInfo = res.Data;
        this.sharedService.setLayoutLoaded(true)
        this.isLoaded = true
      }
      // this.setSloganPlaceholder()
    })
  }
  getCurrentPage(): string {
    return this.sharedService.getCurrenPage()
  }
  isNavLG(): boolean {
    if (this.getCurrentPage() == 'cannot-deliver-location' ||
      this.getCurrentPage() == 'delivery-location' || this.getCurrentPage() == 'new-location')
      return false
    return true
  }
  isLayoutLoaded(): boolean {
    return this.sharedService.getLayoutIsLoaded()
  }
  setSloganPlaceholder() {
    if (this.isNavLG()) {
      let placeholder = '/assets/img/tayar_placeholder.svg'
      this.sharedService.setImagePlaceholder({ imageID: 'slogan', imagePath: this.mainInfo.CompanyLogo, placeholderPath: placeholder })
    }

  }
  isEnLanguage(): boolean {
    return this.sharedService.getCurrentLanguage() == Language.EN
  }
}
